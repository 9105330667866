
body {
    background-color: white;
    color: white;
    margin: 0;
    font-family: 'Avenir', 'Nunito', sans-serif;
    box-sizing: border-box;
}

button {
    transition: background-color 0.5s;
}

button.green {
    background-color: #4ad6ae;
}

button.green:hover {
    background-color: #3ec69f;
}

button.lightGreen {
    background-color: #DBF9F3;
}

button.lightGreen:hover {
    background-color: rgb(174, 247, 232);
}

button.profile {
    background: linear-gradient(to top right, #768ae9, #b467d1);
}

button.panel {
    background-color: rgba(255, 255, 255, 0.16);
}

button.panel:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

button.magenta {
    background-color: #ff5469;
}

button.magenta:hover {
    background-color: #ea3047;
}

button.mediumLightGray {
    background-color: rgba(0, 0, 0, 0.1);
}

button.mediumLightGray:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

button.white {
    background-color: #ffffff;
}

button.white:hover {
    background-color: #eeeeee;
}

button.close {
    background-color: rgb(98, 90, 121, 0.1);
}

button.close:hover {
    background-color: rgba(98, 90 , 121, 0.2);
}

button.forgotPassword {
    background-color: rgba(255, 255, 255, 0.30);
}

button.forgotPassword:hover {
    background-color: rgba(255, 255, 255, 0.40);
}

div.fadeShown {
    visibility:visible;
    opacity:1;
    transition:visibility 0.3s, opacity 0.3s;
    background-color:rgba(255, 255, 255, 0.9);
}

div.fadeHidden {
    visibility:hidden;
    opacity:0;
    transition:visibility 0.3s, opacity 0.3s;
}

div.blur {
    filter:blur(3px);
    transition:filter 0.3s;
    height:100vh;
    overflow:hidden;
}

div.blurNavBar {
    filter:blur(3px);
    transition:filter 0.3s;
    height:100vh;
}

div.darken {
    opacity: 0.7;
    z-index: 2;
}

div.noDarken {
    opacity: 0;
    z-index: -1;
}

div.lightBold {
    font-weight: 450;
};

.fontWeightLighter {
    font-weight: 200;
};

.datePickerField {
    font-size: 14px;
    border-color: red;
};

